/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Row, Col, Card, Button } from "react-bootstrap";

import ImageUploading from "react-images-uploading";

import Select from "react-select";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";

import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as yup from "yup";

import {
  ContentState,
  convertToRaw,
  EditorState,
  convertFromHTML,
} from "draft-js";

/* APIS IMPORT */
import {
  addPropertyDetails,
  getCategory,
  getPropertyDetailsById,
  getSubCategory,
} from "../../../service/api";

import { IMAGE_URL } from "../../../config";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import PlusIcon from "@iconscout/react-unicons/icons/uil-plus";

const BlogDetail = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [blogDesc, setBlogDesc] = useState();
  const [loading, setLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [labelOptions, setLabelOptions] = useState([]);

  const [aboutEditor, setAboutEditor] = useState(null);
  const [descriptionEditor, setDescriptionEditor] = useState(null);

  const [addAmenitiesButton, setAddAmenitiesButton] = useState(false);
  const [addAmenity, setAddAmenity] = useState("");
  const [amenities, setAmenities] = useState([]);

  const [addFeaturesButton, setAddFeaturesButton] = useState(false);
  const [addFeature, setAddFeature] = useState("");
  const [features, setFeatures] = useState([]);

  const [addNearByPlacesButton, setAddNearByPlacesButton] = useState(false);
  const [addNearByPlace, setAddNearByPlace] = useState("");
  const [addNearByPlaceKM, setAddNearByPlaceKM] = useState("");
  const [nearByPlaces, setNearByPlaces] = useState([]);

  /* GET BLOGS LISTING */
  const validationSchema = yup.object().shape({
    propertyName: yup.string().required("Property Name is required"),
    preFerredName: yup.string(),
    shortName: yup.string(),
    floorPlans: yup.string(),
    landSize: yup.string(),
    projectSize: yup.string(),
    registrationNumber: yup.string(),
    launchDate: yup.string(),
    possessionBy: yup.string(),
    totalUnits: yup.string(),
    totalTowers: yup.string(),
    projectType: yup.string(),
    propertyType: yup.string(),
    OccupancyCertificate: yup.string(),
    price: yup.string(),
    rating: yup.number(),
    aboutProperty: yup.string(),
    propertyDescription: yup.string(),
    propertyShortDescription: yup.string(),
    bedRooms: yup.string(),
    bathRooms: yup.string(),
    area: yup.string(),
    floor: yup.string(),
    totalFloor: yup.string(),
    furnished: yup.object(),
    parking: yup.string(),
    direction: yup.string(),
    houseNumber: yup.string(),
    buildingApartmentName: yup.string(),
    mainStreet: yup.string(),
    city: yup.string(),
    state: yup.string(),
    postalCode: yup.string(),
    country: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email(),
    phoneNumber: yup.string(),
    amenities: yup.array(),
    features: yup.array(),
    nearByPlaces: yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      propertyName: "",
      preFerredName: "",
      shortName: "",
      floorPlans: "",
      landSize: "",
      projectSize: "",
      registrationNumber: "",
      launchDate: "",
      possessionBy: "",
      totalUnits: "",
      totalTowers: "",
      projectType: "",
      propertyType: "",
      OccupancyCertificate: "",
      price: "",
      rating: "",
      aboutProperty: "",
      propertyDescription: "",
      propertyShortDescription: "",
      bedRooms: "",
      bathRooms: "",
      area: "",
      floor: "",
      totalFloor: "",
      furnished: "",
      parking: "",
      direction: "",
      houseNumber: "",
      buildingApartmentName: "",
      mainStreet: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      firstName: "Jay Housing",
      lastName: "Agency",
      email: "jayhousingagency@gmail.com",
      phoneNumber: "+919322656348",
      amenities: "",
      features: "",
      nearByPlaces: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {};

      if (values?.category) {
        obj.category = values?.category?.value;
      }
      if (values?.subCategory) {
        obj.subCategory = values?.subCategory?.value;
      }

      if (values?.propertyName) {
        obj.propertyName = values?.propertyName;
      }
      if (values?.preFerredName) {
        obj.preFerredName = values?.preFerredName;
      }
      if (values?.shortName) {
        obj.shortName = values?.shortName;
      }
      if (values?.floorPlans) {
        obj.floorPlans = values?.floorPlans;
      }
      if (values?.landSize) {
        obj.landSize = values?.landSize;
      }
      if (values?.projectSize) {
        obj.projectSize = values?.projectSize;
      }
      if (values?.registrationNumber) {
        obj.registrationNumber = values?.registrationNumber;
      }
      if (values?.launchDate) {
        obj.launchDate = values?.launchDate;
      }
      if (values?.possessionBy) {
        obj.possessionBy = values?.possessionBy;
      }
      if (values?.totalUnits) {
        obj.totalUnits = values?.totalUnits;
      }
      if (values?.totalTowers) {
        obj.totalTowers = values?.totalTowers;
      }
      if (values?.projectType) {
        obj.projectType = values?.projectType;
      }
      if (values?.propertyType) {
        obj.propertyType = values?.propertyType;
      }
      if (values?.OccupancyCertificate) {
        obj.OccupancyCertificate = values?.OccupancyCertificate;
      }
      if (values?.price) {
        obj.price = values?.price;
      }
      if (values?.rating) {
        obj.rating = values?.rating;
      }
      if (values?.aboutProperty) {
        obj.aboutProperty = values?.aboutProperty;
      }
      if (values?.propertyDescription) {
        obj.propertyDescription = values?.propertyDescription;
      }
      if (values?.propertyShortDescription) {
        obj.propertyShortDescription = values?.propertyShortDescription;
      }

      let tempPropertyDetails = {};

      if (values?.bedRooms) {
        tempPropertyDetails.bedRooms = values?.bedRooms;
      }
      if (values?.bathRooms) {
        tempPropertyDetails.bathRooms = values?.bathRooms;
      }
      if (values?.area) {
        tempPropertyDetails.area = values?.area;
      }
      if (values?.floor) {
        tempPropertyDetails.floor = values?.floor;
      }
      if (values?.totalFloor) {
        tempPropertyDetails.totalFloor = values?.totalFloor;
      }
      if (values?.furnished) {
        tempPropertyDetails.furnished = values?.furnished?.value;
      }
      if (values?.parking) {
        tempPropertyDetails.parking = values?.parking;
      }
      if (values?.direction) {
        tempPropertyDetails.direction = values?.direction;
      }

      obj.propertyDetails = tempPropertyDetails;

      let tempLocationDetails = {};
      if (values?.houseNumber) {
        tempLocationDetails.houseNumber = values?.houseNumber;
      }
      if (values?.buildingApartmentName) {
        tempLocationDetails.buildingApartmentName =
          values?.buildingApartmentName;
      }
      if (values?.mainStreet) {
        tempLocationDetails.mainStreet = values?.mainStreet;
      }
      if (values?.city) {
        tempLocationDetails.city = values?.city;
      }
      if (values?.state) {
        tempLocationDetails.state = values?.state;
      }
      if (values?.postalCode) {
        tempLocationDetails.postalCode = values?.postalCode;
      }
      if (values?.country) {
        tempLocationDetails.country = values?.country;
      }
      obj.locationDetails = tempLocationDetails;

      let tempContactDetails = {};
      if (values?.firstName) {
        tempContactDetails.firstName = values?.firstName;
      }
      if (values?.lastName) {
        tempContactDetails.lastName = values?.lastName;
      }
      if (values?.email) {
        tempContactDetails.email = values?.email;
      }
      if (values?.phoneNumber) {
        tempContactDetails.phoneNumber = values?.phoneNumber;
      }

      obj.contactDetails = tempContactDetails;

      if (values?.amenities?.length > 0) {
        obj.amenities = values?.amenities;
      }
      if (values?.features?.length > 0) {
        obj.features = values?.features;
      }
      if (values?.nearByPlaces?.length > 0) {
        obj.nearByPlaces = values?.nearByPlaces;
      }

      setLoading(true);

      addPropertyDetails(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            formik.resetForm();
            navigate("/properties/" + res?.data);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  const handleAddAmenities = () => {
    if (addAmenity) {
      if (amenities?.find((el) => el === addAmenity)) {
        toast.warn("Already Added");
      } else {
        setAmenities([...amenities, addAmenity]);
      }

      setAddAmenity("");
    }
  };

  const handleRemoveAmenities = (index) => {
    setAmenities(amenities?.filter((e) => e !== index));
  };

  const handleAddFeatures = () => {
    if (addFeature) {
      if (features?.find((el) => el === addFeature)) {
        toast.warn("Already Added");
      } else {
        setFeatures([...features, addFeature]);
      }

      setAddFeature("");
    }
  };

  const handleRemoveFeatures = (index) => {
    setFeatures(features?.filter((e) => e !== index));
  };

  const handleAddNearByPlaces = () => {
    if (addNearByPlace) {
      if (nearByPlaces?.find((el) => el?.place === addNearByPlace)) {
        toast.warn("Already Added");
      } else {
        setNearByPlaces([
          ...nearByPlaces,
          { place: addNearByPlace, km: addNearByPlaceKM },
        ]);
      }
      setAddNearByPlaceKM("");
      setAddNearByPlace("");
    }
  };

  const handleRemoveNearByPlaces = (index) => {
    setNearByPlaces(nearByPlaces?.filter((e) => e?.place !== index?.place));
  };
  const getAllCategoryFunction = () => {
    getCategory({ pagination: false }).then((res) => {
      if (res?.status) {
        let tempArray = [];
        res?.data?.map((res) => {
          tempArray.push({ label: res?.name, value: res?._id });
        });
        setCategoryList(tempArray);
      }
    });
  };

  const getAllSubCategoryFunction = () => {
    getSubCategory({ pagination: false }).then((res) => {
      if (res?.status) {
        let tempArray = [];
        res?.data?.map((res) => {
          tempArray.push({
            category: res?.category,
            label: res?.name,
            value: res?._id,
          });
        });
        setSubCategoryList(tempArray);
      }
    });
  };

  useEffect(() => {
    getAllCategoryFunction();
    getAllSubCategoryFunction();
  }, []);

  useEffect(() => {
    formik.setFieldValue("amenities", amenities);
    formik.setFieldValue("features", features);
    formik.setFieldValue("nearByPlaces", nearByPlaces);
  }, [amenities, features, nearByPlaces]);

  return (
    <section className="blog-detail">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Add Property Details</h1>
            </Col>
          </Row>
        </div>

        {/* BLOG DETAIL */}
        <div className="details">
          <Card className="blog-card">
            <form onSubmit={formik.handleSubmit}>
              <Card.Body className="blog-cardbody">
                <div className="row">
                  {/* PROPERTY NAME */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Property Name"
                        name="propertyName"
                        id="propertyName"
                        value={formik.values.propertyName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.propertyName &&
                        formik.touched.propertyName && (
                          <small style={{ color: "red" }}>
                            {formik.errors.propertyName}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY PREFERRED NAME */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Preferred Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Preferred Name"
                        name="preFerredName"
                        id="preFerredName"
                        value={formik.values.preFerredName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.preFerredName &&
                        formik.touched.preFerredName && (
                          <small style={{ color: "red" }}>
                            {formik.errors.preFerredName}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY AGENCY NAME */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Builder Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Builder Name"
                        name="shortName"
                        id="shortName"
                        value={formik.values.shortName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.shortName && formik.touched.shortName && (
                        <small style={{ color: "red" }}>
                          {formik.errors.shortName}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* PROPERTY FLOOR PLANS DISPLAY */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Floor Plans (Eg: 1, 2 & 3)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Eg: 2,3 and 4"
                        name="floorPlans"
                        id="floorPlans"
                        value={formik.values.floorPlans}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.floorPlans &&
                        formik.touched.floorPlans && (
                          <small style={{ color: "red" }}>
                            {formik.errors.floorPlans}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY LAND SIZE */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Carpet Area (Eg: 100 sqft - 200 sqft)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Carpet Area"
                        name="landSize"
                        id="landSize"
                        value={formik.values.landSize}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.landSize && formik.touched.landSize && (
                        <small style={{ color: "red" }}>
                          {formik.errors.landSize}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* PROPERTY PROJECT SIZE */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Project Size (in acres)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project Size"
                        name="projectSize"
                        id="projectSize"
                        value={formik.values.projectSize}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.projectSize &&
                        formik.touched.projectSize && (
                          <small style={{ color: "red" }}>
                            {formik.errors.projectSize}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY REGISTRATION NUMBER */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Registration Number (MahaRERA Number)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registration Number"
                        name="registrationNumber"
                        id="registrationNumber"
                        value={formik.values.registrationNumber}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.registrationNumber &&
                        formik.touched.registrationNumber && (
                          <small style={{ color: "red" }}>
                            {formik.errors.registrationNumber}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY LAUNCH DATE */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Launch Date (Eg. 1 January 2024)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Launch Date"
                        name="launchDate"
                        id="launchDate"
                        value={formik.values.launchDate}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.launchDate &&
                        formik.touched.launchDate && (
                          <small style={{ color: "red" }}>
                            {formik.errors.launchDate}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY POSSESSION DATE */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Possession By (Eg. 1 January 2024)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Posession By"
                        name="possessionBy"
                        id="possessionBy"
                        value={formik.values.possessionBy}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.possessionBy &&
                        formik.touched.possessionBy && (
                          <small style={{ color: "red" }}>
                            {formik.errors.possessionBy}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY TOTAL UNITS*/}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Total Units</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Units"
                        name="totalUnits"
                        id="totalUnits"
                        value={formik.values.totalUnits}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.totalUnits &&
                        formik.touched.totalUnits && (
                          <small style={{ color: "red" }}>
                            {formik.errors.totalUnits}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY TOTAL TOWERS*/}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Total Towers</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Towers"
                        name="totalTowers"
                        id="totalTowers"
                        value={formik.values.totalTowers}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.totalTowers &&
                        formik.touched.totalTowers && (
                          <small style={{ color: "red" }}>
                            {formik.errors.totalTowers}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY TYPE*/}
                  {/* <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Type (Commercial/Industrial/Residential)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project Type"
                        name="projectType"
                        id="projectType"
                        value={formik.values.projectType}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.projectType &&
                        formik.touched.projectType && (
                          <small style={{ color: "red" }}>
                            {formik.errors.projectType}
                          </small>
                        )}
                    </div>
                  </div> */}

                  {/* OCCUPANCY CERTIFICATE */}
                  {/* <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Occupancy Certificate
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Occupancy Certificate"
                        name="OccupancyCertificate"
                        id="OccupancyCertificate"
                        value={formik.values.OccupancyCertificate}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.OccupancyCertificate &&
                        formik.touched.OccupancyCertificate && (
                          <small style={{ color: "red" }}>
                            {formik.errors.OccupancyCertificate}
                          </small>
                        )}
                    </div>
                  </div> */}

                  {/* PRICE */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Property Price (Eg: 1.05 Cr - 1.39 Cr)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Property Price"
                        name="price"
                        id="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.price && formik.touched.price && (
                        <small style={{ color: "red" }}>
                          {formik.errors.price}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* RATING */}
                  {/* <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Rating (from 0 to 5)</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Property Rating"
                        name="rating"
                        id="rating"
                        value={formik.values.rating}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.rating && formik.touched.rating && (
                        <small style={{ color: "red" }}>
                          {formik.errors.rating}
                        </small>
                      )}
                    </div>
                  </div> */}

                  {/* Category */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="category">
                        Category
                      </label>
                      <Select
                        options={categoryList}
                        className="select-control"
                        classNamePrefix="custom"
                        id="category"
                        value={formik.values.category}
                        onChange={(e) => formik.setFieldValue("category", e)}
                      />
                      {formik.errors.category && formik.touched.category && (
                        <small style={{ color: "red" }}>
                          {formik.errors.category}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* Sub-Category */}
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="category">
                        Sub category
                      </label>

                      <Select
                        options={subCategoryList?.filter(
                          (res) =>
                            res?.category === formik.values.category?.value
                        )}
                        className="select-control"
                        classNamePrefix="custom"
                        id="subCategory"
                        value={formik.values.subCategory}
                        onChange={(e) => formik.setFieldValue("subCategory", e)}
                      />
                      {formik.errors.subCategory &&
                        formik.touched.subCategory && (
                          <small style={{ color: "red" }}>
                            {formik.errors.subCategory}
                          </small>
                        )}
                    </div>
                  </div>

                  {/* PROPERTY DESCRIPTION */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="form-group mb-3">
                      <label className="form-label">Property Description</label>
                      <Editor
                        editorClassName="texteditor-control"
                        toolbarClassName="toolbar-control"
                        placeholder="Description "
                        editorState={descriptionEditor}
                        onEditorStateChange={(e) => {
                          setDescriptionEditor(e);
                          formik.setFieldValue(
                            "propertyDescription",
                            draftToHtml(convertToRaw(e.getCurrentContent()))
                          );
                        }}
                      />
                    </div>
                  </div>

                  {/* PROPERTY SHORT DESCRIPTION */}
                  {/* <div className="col-xl-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Property Short Description
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="propertyShortDescription"
                        name="propertyShortDescription"
                        id="propertyShortDescription"
                        value={formik.values.propertyShortDescription}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.propertyShortDescription &&
                        formik.touched.propertyShortDescription && (
                          <small style={{ color: "red" }}>
                            {formik.errors.propertyShortDescription}
                          </small>
                        )}
                    </div>
                  </div> */}

                  {/* ABOUT LOCATION  */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="form-group mt-3">
                      <label className="form-label">About Location</label>
                      <Editor
                        editorClassName="texteditor-control"
                        toolbarClassName="toolbar-control"
                        placeholder="About Property"
                        editorState={aboutEditor}
                        onEditorStateChange={(e) => {
                          setAboutEditor(e);
                          formik.setFieldValue(
                            "aboutProperty",
                            draftToHtml(convertToRaw(e.getCurrentContent()))
                          );
                        }}
                      />
                    </div>
                  </div>

                  {/* HOUSE DETAILS */}
                  <div className="col-xl-12 d-none col-sm-12">
                    <div className="highlight-box mt-3">
                      {/* <h4>House Details</h4> */}

                      <div className="row mt-3">
                        {/* BED ROOMS */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Bed Rooms</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bed Rooms"
                              name="bedRooms"
                              id="bedRooms"
                              value={formik.values.bedRooms}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.bedRooms &&
                              formik.touched.bedRooms && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.bedRooms}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* BATH ROOMS */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Bath Rooms</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bath Rooms"
                              name="bathRooms"
                              id="bathRooms"
                              value={formik.values.bathRooms}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.bathRooms &&
                              formik.touched.bathRooms && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.bathRooms}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* AREA */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Area (sqft)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Area"
                              name="area"
                              id="area"
                              value={formik.values.area}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.area && formik.touched.area && (
                              <small style={{ color: "red" }}>
                                {formik.errors.area}
                              </small>
                            )}
                          </div>
                        </div> */}

                        {/* FLOOR */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Floor Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Floor"
                              name="floor"
                              id="floor"
                              value={formik.values.floor}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.floor && formik.touched.floor && (
                              <small style={{ color: "red" }}>
                                {formik.errors.floor}
                              </small>
                            )}
                          </div>
                        </div> */}

                        {/* TOTAL FLOOR */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Total Floor</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Total Floor"
                              name="totalFloor"
                              id="totalFloor"
                              value={formik.values.totalFloor}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.totalFloor &&
                              formik.touched.totalFloor && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.totalFloor}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* FURNISHED */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="furnished">
                              Furnished
                            </label>
                            <Select
                              options={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                              ]}
                              className="select-control"
                              classNamePrefix="custom"
                              id="furnished"
                              placeholder="Select option"
                              value={formik.values.furnished}
                              onChange={(e) =>
                                formik.setFieldValue("furnished", e)
                              }
                            />
                            {formik.errors.furnished &&
                              formik.touched.furnished && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.furnished}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* PARKING */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Parking</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Parking Area"
                              name="parking"
                              id="parking"
                              value={formik.values.parking}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.parking &&
                              formik.touched.parking && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.parking}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* DIRECTION */}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Direction</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Direction"
                              name="direction"
                              id="direction"
                              value={formik.values.direction}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.direction &&
                              formik.touched.direction && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.direction}
                                </small>
                              )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* LOCATION DETAILS */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="highlight-box mt-3">
                      <h4>Location Details</h4>

                      <div className="row mt-3">
                        {/* HOUSE NUMBER */}
                        {formik.values.subCategory?.label === "Renting" && (
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">House Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="House Number"
                                name="houseNumber"
                                id="houseNumber"
                                value={formik.values.houseNumber}
                                onChange={formik.handleChange}
                              />
                              {formik.errors.houseNumber &&
                                formik.touched.houseNumber && (
                                  <small style={{ color: "red" }}>
                                    {formik.errors.houseNumber}
                                  </small>
                                )}
                            </div>
                          </div>
                        )}
                        {/* <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">House Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="House Number"
                              name="houseNumber"
                              id="houseNumber"
                              value={formik.values.houseNumber}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.houseNumber &&
                              formik.touched.houseNumber && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.houseNumber}
                                </small>
                              )}
                          </div>
                        </div> */}

                        {/* BUILDING/APARTMENT NAME */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Building / Apartment Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Building / Appartment Name"
                              name="buildingApartmentName"
                              id="buildingApartmentName"
                              value={formik.values.buildingApartmentName}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.buildingApartmentName &&
                              formik.touched.buildingApartmentName && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.buildingApartmentName}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* MAIN STREET */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Main Street</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Main Street"
                              name="mainStreet"
                              id="mainStreet"
                              value={formik.values.mainStreet}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.mainStreet &&
                              formik.touched.mainStreet && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.mainStreet}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* CITY */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              id="city"
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.city && formik.touched.city && (
                              <small style={{ color: "red" }}>
                                {formik.errors.city}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* STATE */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">State</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              name="state"
                              id="state"
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.state && formik.touched.state && (
                              <small style={{ color: "red" }}>
                                {formik.errors.state}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* POSTAL-CODE */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Postal Code</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Postal Code"
                              name="postalCode"
                              id="postalCode"
                              value={formik.values.postalCode}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.postalCode &&
                              formik.touched.postalCode && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.postalCode}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* COUNTRY */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              name="country"
                              id="country"
                              value={formik.values.country}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.country &&
                              formik.touched.country && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.country}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* CONTACT DETAILS */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="highlight-box mt-3">
                      <h4>Contact Details</h4>

                      <div className="row mt-3">
                        {/* FIRST NAME */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              name="firstName"
                              id="firstName"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.firstName &&
                              formik.touched.firstName && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.firstName}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* LAST NAME */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              id="lastName"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.lastName &&
                              formik.touched.lastName && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.lastName}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email address"
                              name="email"
                              id="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <small style={{ color: "red" }}>
                                {formik.errors.email}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* PHONE NUMBER */}
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone"
                              name="phoneNumber"
                              id="phoneNumber"
                              value={formik.values.phoneNumber}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.phoneNumber &&
                              formik.touched.phoneNumber && (
                                <small style={{ color: "red" }}>
                                  {formik.errors.phoneNumber}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* AMENITIES */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="highlight-box mt-3">
                      <div className="box-heading">
                        <h4>Amenities </h4>
                        <Button
                          className="import-btn"
                          onClick={() => {
                            setAddAmenitiesButton(!addAmenitiesButton);
                          }}
                        >
                          Add details
                        </Button>
                      </div>

                      {addAmenitiesButton && (
                        <div className="row mt-2">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Amenity Name"
                                name="amenities"
                                id="amenities"
                                value={addAmenity}
                                onChange={(event) => {
                                  setAddAmenity(event?.target?.value);
                                }}
                              />
                              {formik.errors.lastName &&
                                formik.touched.lastName && (
                                  <small style={{ color: "red" }}>
                                    {formik.errors.lastName}
                                  </small>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <Button
                              onClick={handleAddAmenities}
                              className="import-btn"
                            >
                              Save details
                            </Button>
                          </div>
                          <div className="col-xl-6 col-sm-12"></div>
                        </div>
                      )}

                      <div className="d-flex align-items-center mt-2 mobile-resp">
                        {amenities && amenities?.length > 0 ? (
                          amenities?.map((element, index) => {
                            return (
                              <div className="filters" key={index}>
                                <div className="filter-badge text-nowrap ">
                                  <p>{element}</p>
                                  <Button
                                    onClick={() => {
                                      handleRemoveAmenities(element);
                                    }}
                                    className="bg-transparent border-0 p-1 action-btn"
                                  >
                                    <TrashIcon size="15" color="#000" />
                                  </Button>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="no-amenities">No Amenities Found</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* FEATURES */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="highlight-box mt-3">
                      <div className="box-heading">
                        <h4>Features </h4>
                        <Button
                          className="import-btn"
                          onClick={() => {
                            setAddFeaturesButton(!addFeaturesButton);
                          }}
                        >
                          Add details
                        </Button>
                      </div>

                      {addFeaturesButton && (
                        <div className="row mt-2">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Feature Name"
                                name="features"
                                id="features"
                                value={addFeature}
                                onChange={(event) => {
                                  setAddFeature(event?.target?.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="d-flex">
                              <Button
                                onClick={handleAddFeatures}
                                className="import-btn"
                              >
                                Save details
                              </Button>
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12"></div>
                        </div>
                      )}

                      <div className="d-flex align-items-center mt-2 mobile-resp">
                        {features && features?.length > 0 ? (
                          features?.map((element, index) => {
                            return (
                              <div className="filters" key={index}>
                                <div className="filter-badge ">
                                  <p>{element}</p>
                                  <Button
                                    onClick={() => {
                                      handleRemoveFeatures(element);
                                    }}
                                    className="bg-transparent border-0 p-2 action-btn"
                                  >
                                    <TrashIcon size="15" color="#000" />
                                  </Button>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="no-amenities">No Features Found</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* NEARBY PLACES */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="highlight-box mt-3">
                      <div className="box-heading">
                        <h4>Nearby Places </h4>
                        <Button
                          className="import-btn"
                          onClick={() => {
                            setAddNearByPlacesButton(!addNearByPlacesButton);
                          }}
                        >
                          Add details
                        </Button>
                      </div>

                      {addNearByPlacesButton && (
                        <div className="row mt-2">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name of the place"
                                name="nearByPlaces"
                                id="nearByPlaces"
                                value={addNearByPlace}
                                onChange={(event) => {
                                  setAddNearByPlace(event?.target?.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="d-flex">
                              <Button
                                onClick={handleAddNearByPlaces}
                                className="import-btn"
                              >
                                Save details
                              </Button>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12"></div>
                        </div>
                      )}

                      <div className="d-flex align-items-center mt-2 mobile-resp">
                        {nearByPlaces && nearByPlaces?.length > 0 ? (
                          nearByPlaces?.map((element, index) => {
                            return (
                              <div className="filters" key={index}>
                                <div className="filter-badge ">
                                  <div className=" d-block">
                                    <p>{element?.place}</p>
                                    {/* <small className=" text-nowrap">
                                      {element?.km}km away
                                    </small> */}
                                  </div>
                                  <Button
                                    onClick={() => {
                                      handleRemoveNearByPlaces(element);
                                    }}
                                    className="bg-transparent border-0 p-0 m-0 ps-3"
                                  >
                                    <TrashIcon size="15" color="#425AA3" />
                                  </Button>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="no-amenities">No Nearby Places Found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>

              <Card.Footer className="blog-cardfooter">
                <Link to="/properties">
                  <Button className="cancel-btn">Go back</Button>
                </Link>
                <Button
                  disabled={loading}
                  type={loading ? "button" : "submit"}
                  className="primary-btn"
                >
                  {loading ? "Please wait..." : "Save Details"}
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
