/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";

/* ICON IMPORTS */
import ActionIcon from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import PreviousIcon from "@iconscout/react-unicons/icons/uil-previous";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* IMAGE IMPORT */
import Logo from "../../../assets/logo.webp";

/* API IMPORT */
import {
  deletePropertyDetailsById,
  getPropertyDetails,
} from "../../../service/api";

/* IMAGE URL */
import { IMAGE_URL } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PropertyPlaceholder from "../../../assets/property-placeholder.png";

const Properties = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const pageSize = 12;
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setLoading(true);
    setData([]);
    setPage(selectedPage);
  };

  /* DELETE HANDLER FUNCTION */
  const handleDelete = (id) => {
    setLoading(true);
    deletePropertyDetailsById(id)
      .then((res) => {
        if (res?.status) {
          getPropertyData();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* GET BLOGS DATA */
  const getPropertyData = () => {
    setLoading(true);
    const obj = {
      page: page,
      sizePerPage: pageSize,
    };
    getPropertyDetails(obj)
      .then((res) => {
        if (res.status) {
          setTotalDocs(res.data.totalDocs);
          setTotalPages(res?.data?.totalPages);
          setData(res.data?.docs);
        }
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* USE EFFECT */
  useEffect(() => {
    getPropertyData();
  }, [page]);

  return (
    <section className="blogs">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Properties</h1>
              <p style={{ fontSize: "15px" }}>
                Add and manage properties for your website.
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Link to={"/properties/add"} className=" text-decoration-none">
                  <Button className="import-btn">Add Property</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        {/* BLOGS LIST */}
        <div className="blogs-list">
          <Card className="list-card">
            <Card.Header className="list-cardheader">
              <p>
                Showing <b>{totalDocs}</b>{" "}
                {totalDocs === 1 ? "property" : "properties"}
              </p>
            </Card.Header>
            <Card.Body className="list-cardbody">
              <Row className="gy-4">
                {loading ? (
                  <>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={PropertyPlaceholder} alt="Property Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              <Dropdown.Item className="menu-item">
                                Delete Property
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={PropertyPlaceholder} alt="Property Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              <Dropdown.Item className="menu-item">
                                Delete Property
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={PropertyPlaceholder} alt="Property Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              <Dropdown.Item className="menu-item">
                                Delete Property
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src={PropertyPlaceholder} alt="Property Cover" />
                          <Dropdown className="card-action" placement="left">
                            <Dropdown.Toggle>
                              <ActionIcon size="16" color="#323232" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="card-action-menu">
                              <Dropdown.Item className="menu-item">
                                Delete Property
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="blog-details">
                          <div className="filters">
                            <Skeleton className="badge-text-loading" />
                          </div>
                          <Skeleton className="blog-title-loading" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : data?.length > 0 ? (
                  data.map((property, _id) => {
                    return (
                      <>
                        <div className="col-xl-3 col-sm-12" key={_id}>
                          <div className="blog-card">
                            <div className="blog-img">
                              <img
                                src={IMAGE_URL + property?.images?.[0]?.url}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = Logo;
                                }}
                                alt="Property Cover"
                              />
                              <Dropdown
                                className="card-action"
                                placement="left"
                              >
                                <Dropdown.Toggle>
                                  <ActionIcon size="16" color="#323232" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="card-action-menu">
                                  <Dropdown.Item className="menu-item">
                                    <Link
                                      className="text-decoration-none"
                                      style={{ color: "#000" }}
                                      to={"/properties/" + property?._id}
                                    >
                                      Edit Property
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="menu-item"
                                    onClick={() => handleDelete(property?._id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="blog-details">
                              <h5>{property.propertyName}</h5>
                              <p>by {property?.shortName}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <p>No Properties Found</p>
                  </>
                )}
              </Row>

              {/* PAGINATION */}
              <ReactPaginate
                className="blogs-pagination"
                previousLabel={
                  <Button className="blog-pagination-btn">
                    <PreviousIcon color="#323232" size="20" />
                  </Button>
                }
                nextLabel={
                  <Button className="blog-pagination-btn">
                    <NextIcon color="#323232" size="20" />
                  </Button>
                }
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName={"active"}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Properties;
