import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";

import ContactUs from "../pages/dashboard/contact-us";

import Property from "../pages/dashboard/property-details";
import PropertyAdd from "../pages/dashboard/property-details/add-property-details";
import PropertyEdit from "../pages/dashboard/property-details/edit-property-details";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />

        </Routes>

        {/* DASHBOARD ROUTES */}
        <Routes>
          <Route
            path="/contact-us"
            element={
              <DashboardLayout>
                <ContactUs />
              </DashboardLayout>
            }
          />
          <Route
            path="/properties"
            element={
              <DashboardLayout>
                <Property />
              </DashboardLayout>
            }
          />
          <Route
            path="/properties/add"
            element={
              <DashboardLayout>
                <PropertyAdd />
              </DashboardLayout>
            }
          />
          <Route
            path="/properties/:id"
            element={
              <DashboardLayout>
                <PropertyEdit />
              </DashboardLayout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;