import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contact-us`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/contact-us/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// News Letter API
export const getNewsLetter = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/news-letter`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getNewsLetterById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/news-letter/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editNewsLetter = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/news-letter/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteNewsLetter = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/news-letter/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs API
export const addBlog = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blog`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlog = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog/`, +id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlog = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blog/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Property
// Property Add API
export const addPropertyDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/property-details`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPropertyDetails = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/property-details`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPropertyDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/property-details/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editPropertyDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/property-details/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deletePropertyDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/property-details/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Add Floor Plan For Property Details */
export const addFloorPlanForPropertyDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`admin/property-details/floorPlan/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Remove Floor Plan For Property Details */
export const removeFloorPlanForPropertyDetailsById = (id, imageId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/property-details/floorPlan/${id}/${imageId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Add Image For Property Details */
export const addImageForPropertyDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`admin/property-details/image/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Remove Image For Property Details */
export const removeImageForPropertyDetailsById = (id, imageId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/property-details/image/${id}/${imageId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Category Get API
export const getCategory = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/category`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Sub-Category Get API
export const getSubCategory = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/sub-category`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
