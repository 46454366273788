import { Row, Col, Card, Button, Modal } from "react-bootstrap";

import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { addFloorPlanForPropertyDetailsById, removeFloorPlanForPropertyDetailsById } from "../../service/api";
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { IMAGE_URL } from "../../config";

/* GET BLOGS LISTING */
const validationSchema = yup.object().shape({
  floorName: yup.string().required("Floor Name is Required"),
  floorSize: yup.string(),
  floorPrice: yup.string(),
});

const AddFloorPlanForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [images, setImages] = useState([]);

  const handleProfileImage = (imageList) => {
    setImages(imageList);
  };

  const handleRemoveFloorPlan = (floorPlanId) => {
    removeFloorPlanForPropertyDetailsById(propertyId, floorPlanId)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        props.getPropertyDetailsFunction();
      });
  }

  const formik = useFormik({
    initialValues: {
      floorName: "",
      floorSize: "",
      floorPrice: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      if (values?.floorName) {
        formData.append("floorName", values?.floorName);
      }
      if (values?.floorSize) {
        formData.append("floorSize", values?.floorSize);
      }
      if (values?.floorPrice) {
        formData.append("floorPrice", values?.floorPrice);
      }

      if (images) {
        formData.append("image", images?.[0]?.file);
      }

      setLoading(true);

      addFloorPlanForPropertyDetailsById(propertyId, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // setAddBlogModal(false);
            // getBlogData();
            // setFile(null);
            // setFile1(null);
            // setPreviewUrl(null);
            // setPreviewUrl1(null);
            formik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
          props.getPropertyDetailsFunction();
        });
    },
  });

  useEffect(() => {
    if (props) {
      setPropertyId(props?.propertyId);
      setPropertyDetails(props?.propertyDetails);
    }
  }, [props]);
  return (
    <>
      <Card.Body className="blog-cardbody">
        <div className="page-head p-0">
          <Row className="align-center">
            <Col lg="8" sm="12">
              {propertyDetails &&
                propertyDetails?.floorPlansList?.length > 0 ? (
                <p style={{ fontSize: "15px" }}>
                  {propertyDetails?.floorPlansList?.length} Floor Plans Found
                </p>
              ) : (
                <p style={{ fontSize: "15px" }}>No Floor Plans Found</p>
              )}
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start h-25">
                <Button
                  onClick={() => {
                    setViewModal(!viewModal);
                  }}
                  className="add-image-btn"
                >
                  Add Floor Plan
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-3">
          {propertyDetails &&
            propertyDetails?.floorPlansList?.length > 0 &&
            propertyDetails?.floorPlansList?.map((element, index) => {
              return (
                <Col sm={"12"} md={"4"}>
                  <Card className="floor-plan">
                    <div className="featured-box bg-black text-white pb-0" onClick={() => { handleRemoveFloorPlan(element?._id) }} style={{ cursor: "pointer" }}>
                      <p className="text-white fw-bold">Remove <TrashIcon size="14" color="#fff" /></p>
                    </div>
                    <p>
                      <strong>Floor Name: </strong>
                      {element?.floorName}
                    </p>
                    <p>
                      <strong>Floor Size: </strong>
                      {element?.floorSize}
                    </p>
                    <img alt="" src={IMAGE_URL + element?.image?.url} />
                  </Card>
                </Col>
              );
            })}
        </Row>
        <Modal
          centered
          show={viewModal}
          size="lg"
          onHide={() => setViewModal(!viewModal)}
        >
          <Modal.Header className="upload-property-modal-header">
            <h5>Add Floor Plan</h5>
          </Modal.Header>
          <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="floorName">
                      Floor Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="floorName"
                      id="floorName"
                      placeholder="Floor Name"
                      onChange={formik.handleChange}
                      value={formik?.values?.floorName}
                      defaultValue={formik?.values?.floorName}
                    />
                    {formik.errors.floorName && formik.touched.floorName && (
                      <small style={{ color: "red" }}>
                        {formik.errors.floorName}
                      </small>
                    )}
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="floorSize">
                      Floor Size <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="floorSize"
                      id="floorSize"
                      placeholder="Floor Size"
                      onChange={formik.handleChange}
                      value={formik?.values?.floorSize}
                      defaultValue={formik?.values?.floorSize}
                    />
                    {formik.errors.floorSize && formik.touched.floorSize && (
                      <small style={{ color: "red" }}>
                        {formik.errors.floorSize}
                      </small>
                    )}
                  </div>
                </Col>

                <Col className="">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fName">
                      Image <span>*</span>
                    </label>
                    <ImageUploading
                      value={images}
                      onChange={(e) => {
                        handleProfileImage(e);
                      }}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        onImageUpdate,
                      }) => (
                        <div className="agent-profile-img">
                          <div
                            className="img-selection-box"
                            onClick={onImageUpload}
                          >
                            <div>
                              <ImportIcon size="20" color="#323232" />
                              <p>Upload from local computer</p>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div key={index} className="uploaded-image">
                              <img
                                src={image["data_url"]}
                                alt="Uploaded Profile"
                              />
                              <div className="action-btns">
                                <Button
                                  className="edit-btn"
                                  onClick={onImageUpdate}
                                >
                                  <EditIcon size="15" color="#323232" />
                                </Button>
                                <Button
                                  className="remove-btn"
                                  onClick={onImageRemove}
                                >
                                  <TrashIcon size="15" color="#323232" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => setViewModal(!viewModal)}
              >
                Cancel
              </Button>
              {loading ? (
                <Button className="proceed-btn" type="button">
                  Please Wait...
                </Button>
              ) : (
                <Button className="proceed-btn" type="submit">
                  Save details
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </Card.Body>
    </>
  );
};

export default AddFloorPlanForm;
