import { Row, Col, Card, Button, Modal } from "react-bootstrap";

import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import {
  addImageForPropertyDetailsById,
  removeImageForPropertyDetailsById,
} from "../../service/api";
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { IMAGE_URL } from "../../config";

/* GET BLOGS LISTING */
const validationSchema = yup.object().shape({
  isFeatured: yup.boolean().required("Select Is Featured Option"),
});

const AddImageForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [images, setImages] = useState([]);

  const handleProfileImage = (imageList) => {
    setImages(imageList);
  };

  const handleRemoveImage = (imageId) => {
    removeImageForPropertyDetailsById(propertyId, imageId)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        props.getPropertyDetailsFunction();
      });
  };

  const formik = useFormik({
    initialValues: {
      isFeatured: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("isFeatured", false);

      if (images.length > 0) {
        images?.forEach((ele) => {
          formData.append("image", ele?.file);
        });

        setLoading(true);

        addImageForPropertyDetailsById(propertyId, formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              formik.resetForm();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
            props.getPropertyDetailsFunction();
          });
      } else {
        toast.error("Please select images");
      }
    },
  });

  useEffect(() => {
    if (props) {
      setPropertyId(props?.propertyId);
      setPropertyDetails(props?.propertyDetails);
    }
  }, [props]);

  return (
    <>
      <Card.Body className="blog-cardbody">
        <div className="page-head p-0">
          <div className="d-flex align-items-center justify-content-between mobile-resp-2">
            {propertyDetails && propertyDetails?.images?.length > 0 ? (
              <div>
                <p style={{ fontSize: "15px" }}>
                  {propertyDetails?.images?.length} Images Found
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "400",
                    fontStyle: "italic",
                  }}
                >
                  Note: The first image added will be the cover image for the
                  property
                </p>
              </div>
            ) : (
              <div>
                <p style={{ fontSize: "15px" }}>No Images Found</p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "400",
                    fontStyle: "italic",
                  }}
                >
                  Note: The first image added will be the cover image for the
                  property
                </p>
              </div>
            )}
            <Button
              onClick={() => {
                setViewModal(!viewModal);
              }}
              className="add-image-btn"
            >
              Add Image
            </Button>
          </div>
        </div>
        <Row className="mt-3">
          {propertyDetails &&
            propertyDetails?.images?.length > 0 &&
            propertyDetails?.images?.map((element, index) => {
              return (
                <Col sm={"12"} md={"3"}>
                  <Card
                    className="border-white"
                    style={{ position: "relative" }}
                  >
                    <img
                      alt=""
                      src={IMAGE_URL + element?.url}
                      className="property-image"
                    />

                    <div
                      className="featured-box"
                      onClick={() => {
                        handleRemoveImage(element?._id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p>
                        Remove <TrashIcon size="14" color="#323232" />
                      </p>
                    </div>
                  </Card>
                </Col>
              );
            })}
        </Row>

        <Modal
          centered
          show={viewModal}
          size="lg"
          onHide={() => setViewModal(!viewModal)}
        >
          <Modal.Header className="upload-property-modal-header">
            <h5>Add Property Images (Maximum 5)</h5>
          </Modal.Header>
          <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col xl="12" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fName">
                      Image <span>*</span>
                    </label>
                    <ImageUploading
                      value={images}
                      onChange={(e) => {
                        handleProfileImage(e);
                      }}
                      multiple={true}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        onImageUpdate,
                      }) => (
                        <div className="agent-profile-img">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-sm-12">
                              <div
                                className="img-selection-box"
                                onClick={onImageUpload}
                              >
                                <div>
                                  <ImportIcon size="20" color="#323232" />
                                  <p>Upload from local computer</p>
                                </div>
                              </div>
                            </div>
                            {imageList.map((image, index) => (
                              <div className="col-xl-6 col-lg-6 col-sm-12">
                                <div key={index} className="uploaded-image">
                                  <img
                                    src={image["data_url"]}
                                    alt="Uploaded Profile"
                                  />
                                  <div className="action-btns">
                                    <Button
                                      className="edit-btn"
                                      onClick={onImageUpdate}
                                    >
                                      <EditIcon size="15" color="#323232" />
                                    </Button>
                                    <Button
                                      className="remove-btn"
                                      onClick={onImageRemove}
                                    >
                                      <TrashIcon size="15" color="#323232" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => setViewModal(!viewModal)}
              >
                Cancel
              </Button>
              {loading ? (
                <Button className="proceed-btn" type="button">
                  Please Wait...
                </Button>
              ) : (
                <Button className="proceed-btn" type="submit">
                  Save details
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </Card.Body>
    </>
  );
};

export default AddImageForm;
