import React from "react";

const DashboardFooter = () => {
  return (
    <footer className="auth-footer">
      <div className="container">
        <div className="footer-options">
          <p>© 2024 Jay Housing Agency. All Rights Reserved.</p>
          {/* LINKS */}
          <div className="footer-links">
            <p>
              Powered by{" "}
              <a href="https://www.roundtechsquare.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', fontWeight: '600', color: '#000' }}>RoundTechSquare</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
